<template>
  <!-- Content Header (Page header) -->
  <div class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-6">
          <h1 class="m-0">{{ mytitle }}</h1>
        </div>
        <!-- /.col -->
        <div class="col-sm-6">
          <ol class="breadcrumb float-sm-right">
            <li class="breadcrumb-item">晴鈦朗</li>
            <li class="breadcrumb-item active">{{ mytitle }}</li>
          </ol>
        </div>
        <!-- /.col -->
      </div>
      <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
  </div>
  <!-- /.content-header -->
</template>

<script>
export default {
  data() {
    return {
      mytitle: '123'
    }
  },
  watch: {
    $route(route) {
      // if you go to the redirect page, do not update the breadcrumbs
      if (route.path.startsWith('/redirect/')) {
        return
      }
      this.getBreadcrumb()
    }
  }, 
  created() {
    this.getBreadcrumb()
  },
  methods: {
    getBreadcrumb() {

      if(this.$route.meta)
      {
        this.mytitle = this.$route.meta.title;
      }
      else {
        this.mytitle = "沒標題";
      }

      // only show routes with meta.title
      //let matched = this.$route.matched.filter(item => item.meta && item.meta.title);
      //const first = matched[0];
      //
      //  this.mytitle = first.item.meta.title;     
    },
  }

}
</script>

<style></style>