<template>
    <body class="hold-transition sidebar-mini layout-fixed">
      <div class="wrapper">

        <Navbar></Navbar>
        <left-menu></left-menu>
  
        <div class="content-wrapper">
          
          <ContentHeader></ContentHeader>
  
          <section class="content">
            <div class="container-fluid">
                <router-view></router-view>
            </div>
          </section>          
        </div>   
        <ControlSidebar></ControlSidebar>
        
      </div>
    </body>
  </template>
  
  <script>
  import $ from 'jquery'
  import "@/../node_modules/admin-lte/plugins/select2-bootstrap4-theme/select2-bootstrap4.min.css"
  import "@/../node_modules/admin-lte/plugins/select2/css/select2.min.css"
  
  import ContentHeader from "@/dashboard/ContentHeader.vue"
  import Navbar from "@/dashboard/Navbar.vue";
  import ControlSidebar from "@/dashboard/ControlSidebar.vue"
  import LeftMenu from '@/dashboard/LeftMenu.vue';
  
  
  export default {
    name: "App",
    components: {
      ContentHeader,
      Navbar,
      LeftMenu,
      ControlSidebar
    },
    methods: {
      initializeSelect2() {
        //Initialize Select2 Elements
        $(".select2").select2();
  
        //Initialize Select2 Elements
        $(".select2bs4").select2({
          theme: "bootstrap4",
        });
      },
      debuglog(msg) {
        console.log(msg);
      }
    },
    mounted() {
      this.initializeSelect2();
      // var test = this.$store.state.token;      
      // if(test=="") this.$router.push({name : "login"});
    }
  };
  </script>
  
  <style>
  </style>
  