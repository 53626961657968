import axios from 'axios';
import { Gettoken, ClearStore } from '@/support/cache.js'
import Swal from 'sweetalert2'
import router from "@/router";


axios.defaults.baseURL = process.env.VUE_APP_API_URL
axios.interceptors.request.use(function (config) {
  //config.headers['X-Binarybox-Api-Key'] = process.env.VUE_APP_API_KEY;
  config.headers['Content-Type'] = 'application/json';
  return config;
});


// 添加请求拦截器
axios.interceptors.request.use(function (config) {
  // 驗證toekn
  const token = Gettoken();

  if (token) {
    config.headers['Authorization'] = 'Bearer ' + token
  }
  else {
    router.push({ name: "login" });
  }


  return config;
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error);
});


/// ----------------------------------------
///  Api回應統一異常處理
/// ----------------------------------------
axios.interceptors.response.use(
  (response) => {

    if (response.status === 200) {

      if (response.data.code && response.data.code == 200) {
        Swal.fire({
          icon: 'success',
          title: response.data.message,
          showConfirmButton: false,
          timer: 1500
        });
      }
      else if (response.data.message)
      {
        let info = response.data;
        Swal.fire({
          icon: 'warning',
          title: info.message,
          showConfirmButton: true
          
        });
      }

      return response.data;
    }
  }, (error) => {

    if (error.response && error.response.data) {

      if (error.response.status === 401) {
        ClearStore();
        router.push({ name: "login" });
      }
      if (error.response.status === 428) {
        alert(JSON.stringify(error.response.data));
      }
      else
      {
        if (error.response.data.message) {
          Swal.fire({
            icon: 'error',
            title: error.response.data.message,
            showConfirmButton: false,
            timer: 1500
          });
        }        
      }
      
    }

    return Promise.reject(error.message);
  });


/// ----------------------------------------
/// get请求
/// ----------------------------------------
export function apiGet(url, data = {}) {
  return axios.get(url, data);
}

/// ----------------------------------------
/// post请求
/// ----------------------------------------
export function apiPost(url, data = {}) {
  return axios.post(url, data);
}

/// ----------------------------------------
/// 請求上傳檔案
/// ----------------------------------------
export function filepost(url, data = {}) {
  return axios.post(url, data);
}
