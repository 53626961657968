export function ClearStore()
{
    localStorage.clear();
}

export function Gettoken() 
{
    return localStorage.getItem('token'); 
}

export function Settoken(token)
{
    localStorage.setItem('token', token)
}



