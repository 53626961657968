// import axios from 'axios';
import { apiPost, apiGet } from './service'

export function gethome() {
	return apiGet("/api/home/");
}


export function login(data) {
	return apiPost("/api/login", data);
}

export function logout() {
	return apiPost("/api/logout");
}
