<template>
<div class="modal fade" id="houseCodeModal">
  <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
              <h4 class="modal-title">Large Modal</h4>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <p>One fine body&hellip;</p>
            </div>
            <div class="modal-footer justify-content-between">
              <button type="button" class="btn btn-default" data-dismiss="modal">關閉</button>
              <button type="button" class="btn btn-primary">選取</button>
        </div>
      </div>
          <!-- /.modal-content -->
    </div>
        <!-- /.modal-dialog -->
</div>   
</template>

<script>
export default {
  name :'HouseCodeSelect'
};
</script>