<template>
  <!-- Navbar -->
  <nav class="main-header navbar navbar-expand navbar-white navbar-light">
    <!-- Left navbar links -->
    <ul class="navbar-nav">
      <li class="nav-item">
        <a class="nav-link" data-widget="pushmenu" href="#" role="button">
          <i class="fas fa-bars"></i>          
        </a>
      </li>
      <!--
      <li class="nav-item">
        <button class="btnbtn-app btn-seconday topbutton">解除綁定</button>  
      </li>
      <li class="nav-item">
        <button class="btnbtn-app btn-primary topbutton" @click="pickHourseCode()">綁定案場</button>  
      </li>
      <li>
      <div class="house"></div>
      </li>
     -->
    </ul>

  </nav>
  <!-- /.navbar -->
  <HouseCodeSelect></HouseCodeSelect>
</template>

<script>
import $ from 'jquery'
import HouseCodeSelect from '@/select/HouseCodeSelect.vue'

export default {
  components: {    
    HouseCodeSelect
  },
  methods: {
    pickHourseCode() {
                $("#houseCodeModal").modal('show');
            }
  }
}
</script>
<style>
.topbutton {
  margin-left: 4px;
  margin-top: 4px;
}
.house {
    margin-left: 20px;
    margin-top: 6px;
}
</style>