import { createRouter, createWebHistory } from 'vue-router'
import Layout from '@/layout'
import LoginView from '../layout/login.vue'

export const routes = [
	{
		path: '/',
		name: 'login',
		component: LoginView,
		powerid: 1
	},
	{
	path: '/admin',
	name: 'admin',
	redirect: '/admin/home',
	component: Layout,
	meta: { title: '', icon: 'form' },
	children: [
		{
			name: 'admin',
			path: '/admin/home',
			component: () => import('../views/Home/Index'),
			meta: { title: '首頁', icon: 'form' }
		},

	{
		name: 'SIM卡管理',
		path: 'sim',
		redirect: '/admin/sim/index',
		icon: 'cil-calculator',
		children: [
			{
				component: () => import('../views/Sim/List'),
				name: 'SIMlist',
				path: '/admin/sim/index',
				meta: { title: 'SIM卡資訊', icon: 'form' },
			},
			{
				name: 'SIMedit',
				path: '/admin/sim/edit/:id',
				component: () => import('../views/Sim/Edit'),
				meta: { title: 'SIM卡資訊', icon: 'form' },
			},
			{
				name: 'stockuscreate',
				path: '/admin/sim/create',
				component: () => import('../views/Sim/Create'),
				meta: { title: 'SIM卡資訊', icon: 'form' },
			}
		]
	},
	{
		name: '監控盒管理',
		path: 'Dtu',
		redirect: '/admin/Dtu/index',
		icon: 'cil-calculator',
		children: [
		{
			component: () => import('../views/Dtu/List'),
			name: 'Dtulist',
			path: '/admin/Dtu/index',
			meta: { title: '監控盒清單', subtitle: '瀏覽', icon: 'form' },
		},
		{
			name: 'Dtuedit',
			path: '/admin/Dtu/edit/:id',
			component: () => import('../views/Dtu/Edit'),
			meta: { title: '監控盒清單', subtitle: '編輯', icon: 'form' },
		},
		{
			name: 'Dtucreate',
			path: '/admin/Dtu/create',
			component: () => import('../views/Dtu/Create.vue'),
			meta: { title: '監控盒清單', subtitle: '新增', icon: 'form' },
		}
		]
	},
	{
		name: '太陽能面板組',
		path: 'Board',
		redirect: '/admin/Board/index',
		icon: 'cil-calculator',
		children: [
		{
			component: () => import('../views/Board/List'),
			name: 'Boardlist',
			path: '/admin/Board/index',
			meta: { title: '太陽能面板組', subtitle: '瀏覽', icon: 'form' },
		},
		{
			name: 'Boardedit',
			path: '/admin/Board/edit/:id',
			component: () => import('../views/Board/Edit'),
			meta: { title: '太陽能面板組', subtitle: '編輯', icon: 'form' },
		},
		{
			name: 'Boardcreate',
			path: '/admin/Board/create',
			component: () => import('../views/Board/Create'),
			meta: { title: '太陽能面板組', subtitle: '新增', icon: 'form' },
		}
		]
	},
	{
		name: '逆變器設置',
		path: 'Inverter',
		redirect: '/admin/Inverter/index',
		icon: 'cil-calculator',
		children: [
		{
			component: () => import('../views/Inverter/List'),
			name: 'Inverterlist',
			path: '/admin/Inverter/index',
			meta: { title: '逆變器設置', subtitle: '瀏覽', icon: 'form' },
		},
		{
			name: 'Inverteredit',
			path: '/admin/Inverter/edit/:id',
			component: () => import('../views/Inverter/Edit'),
			meta: { title: '逆變器設置', subtitle: '編輯', icon: 'form' },
		},
		{
			name: 'Invertercreate',
			path: '/admin/Inverter/create',
			component: () => import('../views/Inverter/Create'),
			meta: { title: '逆變器設置', subtitle: '新增', icon: 'form' },
		}
		]
	},
	{
		name: '驅動程式代碼',
		path: 'Drivers',
		redirect: '/admin/Drivers/index',
		icon: 'cil-calculator',
		children: [
		{
			component: () => import('../views/Drivers/List'),
			name: 'Driverslist',
			path: '/admin/Drivers/index',
			meta: { title: '驅動程式代碼', subtitle: '瀏覽', icon: 'form' },
		},
		{
			name: 'Driversedit',
			path: '/admin/Drivers/edit/:id',
			component: () => import('../views/Drivers/Edit'),
			meta: { title: '驅動程式代碼', subtitle: '編輯', icon: 'form' },
		},
		{
			name: 'Driverscreate',
			path: '/admin/Drivers/create',
			component: () => import('../views/Drivers/Create'),
			meta: { title: '驅動程式代碼', subtitle: '新增', icon: 'form' },
		}
		]
	},
	{
		name: '汰除逆變器管理',
		path: 'InverterBroken',
		redirect: '/admin/InverterBroken/index',
		icon: 'cil-calculator',
		children: [
		{
			component: () => import('../views/InverterBroken/List'),
			name: 'InverterBrokenlist',
			path: '/admin/InverterBroken/index',
			meta: { title: '汰除逆變器管理', subtitle: '瀏覽', icon: 'form' },
		},
		{
			name: 'InverterBrokenedit',
			path: '/admin/InverterBroken/edit/:id',
			component: () => import('../views/InverterBroken/Edit'),
			meta: { title: '汰除逆變器管理', subtitle: '編輯', icon: 'form' },
		},
		{
			name: 'InverterBrokencreate',
			path: '/admin/InverterBroken/create',
			component: () => import('../views/InverterBroken/Create'),
			meta: { title: '汰除逆變器管理', subtitle: '新增', icon: 'form' },
		}
		]
	},
	{
		name: '客戶資料維護',
		path: 'Main',
		redirect: '/admin/Main/index',
		icon: 'cil-calculator',
		children: [
		{
			component: () => import('../views/Main/List'),
			name: 'Mainlist',
			path: '/admin/Main/index',
			meta: { title: '客戶資料維護', subtitle: '瀏覽', icon: 'form' },
		},
		{
			name: 'Mainedit',
			path: '/admin/Main/edit/:id',
			component: () => import('../views/Main/Edit'),
			meta: { title: '客戶資料維護', subtitle: '編輯', icon: 'form' },
		},
		{
			name: 'Maincreate',
			path: '/admin/Main/create',
			component: () => import('../views/Main/Create'),
			meta: { title: '客戶資料維護', subtitle: '新增', icon: 'form' },
		}
		]
	},
	{
		name: '單太陽能板(各廠牌) ',
		path: 'Panel',
		redirect: '/admin/Panel/index',
		icon: 'cil-calculator',
		children: [
		{
			component: () => import('../views/Panel/List'),
			name: 'Panellist',
			path: '/admin/Panel/index',
			meta: { title: '單太陽能板(各廠牌) ', subtitle: '瀏覽', icon: 'form' },
		},
		{
			name: 'Paneledit',
			path: '/admin/Panel/edit/:id',
			component: () => import('../views/Panel/Edit'),
			meta: { title: '單太陽能板(各廠牌) ', subtitle: '編輯', icon: 'form' },
		},
		{
			name: 'Panelcreate',
			path: '/admin/Panel/create',
			component: () => import('../views/Panel/Create'),
			meta: { title: '單太陽能板(各廠牌) ', subtitle: '新增', icon: 'form' },
		}
		]
	},
	{
		name: '系統帳號管理',
		path: 'Admin',
		redirect: '/admin/Admin/index',
		icon: 'cil-calculator',
		children: [
		{
			component: () => import('../views/Admin/List'),
			name: 'Adminlist',
			path: '/admin/Admin/index',
			meta: { title: '系統帳號管理', subtitle: '瀏覽', icon: 'form' },
		},
		{
			name: 'Adminedit',
			path: '/admin/Admin/edit/:id',
			component: () => import('../views/Admin/Edit'),
			meta: { title: '系統帳號管理', subtitle: '編輯', icon: 'form' },
		},
		{
			name: 'Admincreate',
			path: '/admin/Admin/create',
			component: () => import('../views/Admin/Create'),
			meta: { title: '系統帳號管理', subtitle: '新增', icon: 'form' },
		}
		]
	},
	{
		name: '系統帳號管理',
		path: 'Admin',
		redirect: '/admin/Admin/index',
		icon: 'cil-calculator',
		children: [
		{
			component: () => import('../views/Admin/List'),
			name: 'Adminlist',
			path: '/admin/Admin/index',
			meta: { title: '系統帳號管理', subtitle: '瀏覽', icon: 'form' },
		},
		{
			name: 'Adminedit',
			path: '/admin/Admin/edit/:id',
			component: () => import('../views/Admin/Edit'),
			meta: { title: '系統帳號管理', subtitle: '編輯', icon: 'form' },
		},
		{
			name: 'Admincreate',
			path: '/admin/Admin/create',
			component: () => import('../views/Admin/Create'),
			meta: { title: '系統帳號管理', subtitle: '新增', icon: 'form' },
		}
		]
	},
	{
		name: '系統參數維護',
		path: 'Setting',
		redirect: '/admin/Setting/index',
		icon: 'cil-calculator',
		children: [
		{
			component: () => import('../views/Setting/List'),
			name: 'Settinglist',
			path: '/admin/Setting/index',
			meta: { title: '系統參數維護', subtitle: '瀏覽', icon: 'form' },
		},
		{
			name: 'Settingedit',
			path: '/admin/Setting/edit/:id',
			component: () => import('../views/Setting/Edit'),
			meta: { title: '系統參數維護', subtitle: '編輯', icon: 'form' },
		},
		{
			name: 'Settingcreate',
			path: '/admin/Setting/create',
			component: () => import('../views/Setting/Create'),
			meta: { title: '系統參數維護', subtitle: '新增', icon: 'form' },
		}
		]
	},
	{
		name: '發電基本資料',
		path: '/admin/power',
		redirect: '/admin/power/day',
		icon: 'cil-calculator',
		children: [
		{
			component: () => import('../views/Power/day'),
			name: 'PowerDayendlist',
			path: '/admin/power/day',
			meta: { title: '發電基本資料', subtitle: '發電基本資料-日', icon: 'form' },
		},
		{
			component: () => import('../views/Power/week'),
			name: 'PowerWeekendlist',
			path: '/admin/power/week',
			meta: { title: '發電基本資料', subtitle: '發電基本資料-週', icon: 'form' },
		},
		{
			component: () => import('../views/Power/month'),
			name: 'PowerMonthendlist',
			path: '/admin/power/month',
			meta: { title: '發電基本資料', subtitle: '發電基本資料-月', icon: 'form' },
		},

		]
	},
	{
		name: '送件文書登打',
		path: 'Document',
		redirect: '/admin/Document/index',
		icon: 'cil-calculator',
		children: [
		{
			component: () => import('../views/Document/List'),
			name: 'Documentlist',
			path: '/admin/Document/index/:housecode',
			meta: { title: '送件文書登打', subtitle: '文件列表', icon: 'form' },
		},
		{
			name: 'Documentedit',
			path: '/admin/Document/edit/:id',
			component: () => import('../views/Document/Edit'),
			meta: { title: '送件文書登打', subtitle: '編輯', icon: 'form' },
		},
		{
			name: 'Documentcreate',
			path: '/admin/Document/create/:code',
			component: () => import('../views/Document/Create'),
			meta: { title: '送件文書登打', subtitle: '新增', icon: 'form' },
		}
		]
	},
	{
		name: '設置者',
		path: 'Customer',
		redirect: '/admin/Customer/index',
		icon: 'cil-calculator',
		children: [
		{
			component: () => import('../views/Customer/List'),
			name: 'Customerlist',
			path: '/admin/Customer/index',
			meta: { title: '設置者', subtitle: '瀏覽', icon: 'form' },
		},
		{
			name: 'Customeredit',
			path: '/admin/Customer/edit/:id',
			component: () => import('../views/Customer/Edit'),
			meta: { title: '設置者', subtitle: '編輯', icon: 'form' },
		},
		{
			name: 'Customercreate',
			path: '/admin/Customer/create',
			component: () => import('../views/Customer/Create'),
			meta: { title: '設置者', subtitle: '新增', icon: 'form' },
		}
		]
	},
//#A#
	{
		name: '系統管理',
		path: '/admin/sys',
		redirect: '/admin/user/index',
		icon: 'cil-star',
		children: [
			{
				name: '管理員維護',
				path: '/admin/user/index'
			},
			{
				name: '選單維護',
				path: '/admin/menuset/index'
			},
			{
				name: '選單分類',
				path: '/admin/module/index'
			},
		],
	}
	]
	}
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
})

export default router
